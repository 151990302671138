/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, useRef } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import API from "../../Api/Api";

const PsychAiCore = () => {
	const UserData = JSON.parse(localStorage.getItem("Researschadmin"));
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const megID = queryParams.get("id");
	const [isRecording, setIsRecording] = useState(false);
	const textareaRef = useRef(null);
	const [rows, setRows] = useState(1);
	const navigate = useNavigate();
	const [UserInfo, setUserInfo] = useState([]);
	const [AiInputChat, setAiInputChat] = useState("");
	const [AIConversation, setAIConversation] = useState([]);
	const [isGenerating, setisGenerating] = useState("");
	const [chatHistory, setChatHistory] = useState([]);
	const [AIChat, setAIChat] = useState([]);
	const [currentChatId, setcurrentChatId] = useState("");
	const [perviousChatList, setPerviousChatList] = useState([])

	const convertBlobToBinaryData = (blob) => {

		const reader = new FileReader();
		reader.onload = () => {
			const binaryData = reader.result;
			sendBinaryDataToBackend(binaryData, blob);
		};
		reader.readAsArrayBuffer(blob);
	};
	const sendBinaryDataToBackend = async (binaryData, blob, phone) => {
		const data = new FormData();

		data.append("file", blob, "output.mp3");
		data.append("phone", UserData?.data?.phone)
		const encrypted = "51565400e6d1cb014ed02db50fa535b7"
		// /api/aiconnect/creatchattranscribe
		axios
			.post(`https://aitapi.rytlife.com:8001/transcribe_cms_connect`, data, {
				headers: {
					// Authorization: UserData.token,

					'Content-Type': 'multipart/form-data',
					'x-api-key': encrypted
				},
			})
			.then((response) => {
				if (response.data.transcription) {
					handleSubmitChat(response.data.transcription)
				}
			})
			.catch(function (error) {
				console.log(error);
				toast.error(error?.data?.message);
			});
	};


	const recorderControls = useAudioRecorder(
		{
			noiseSuppression: true,
			echoCancellation: true,
		},
		(err) => console.table(err) // onNotAllowedOrFound
	);
	const addAudioElement = (blob) => {
		convertBlobToBinaryData(blob);
	};


	const onRecord = (recState) => {
		setIsRecording(!recState);
	};


	useEffect(() => {
		getChatMsgID();
		chatlisthistory();
		if (megID) {
			getChatHistory(megID);
			setcurrentChatId(megID);
		}
		getChatHistory();
	}, [])


	useEffect(() => {
		const textarea = textareaRef.current;
		if (rows < 5) {
			setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
		}
		if (AiInputChat.length === 0) {
			setRows(1);
		}

		// if (textarea.clientHeight < textarea.scrollHeight) {
		//   setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
		// }else if(textarea.clientHeight > textarea.scrollHeight){
		//   setRows(Math.max(1, Math.floor(textarea.srollHeight / 30)));
		// }
	}, [AiInputChat]);

	const getChatMsgID = () => {
		API.Therapist.CreatMessageid({
			data: {
				phone: UserData?.data?.phone,
				name: UserData?.data?.name
			}
		}).then((response) => {
			let data = JSON.parse(response.data.data)
			setAIChat(JSON.parse(response.data.data));
			setcurrentChatId(data.message_id);
		}).catch((err) => {
			console.log(err)
		})
	}

	const chatlisthistory = () => {
		API.Therapist.Aichatlisthistory()
			.then((response) => {
				if (response.data.status === 1) {
					setPerviousChatList(response.data.userchat)
				}
			}).catch((err) => {
				console.log(err)
			})
	}


	const getChatHistory = (megID) => {
		API.Therapist.ChatHistory({
			msgId: megID,
			name: UserData?.data?.name,
			phone: UserData?.data?.phone,
		})
			.then((response) => {
				// let data = JSON.parse(response.data.data)
				if (response?.data?.status === 1) {
					// setChatHistory(response?.data?.userchat)
					if (megID) {
						setAIConversation(response.data.userchat)
					} else {
						setChatHistory(response.data.userchat)
					}
				}
				;
			}).catch((err) => {
				console.log(err)
			})
	}




	const handleChange = (e) => {
		setAiInputChat(e.target.value);
	};

	// =====================api call for chat section =================================

	const handleSubmitChat = (Msg) => {

		// if (AiInputChat.length) {
		setisGenerating(true);
		AIConversation?.length == 0
			? setAIConversation((prevArray) => [
				...prevArray,
				{
					content: AIChat?.output?.content,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "assistant",
				},
				{
					content: Msg,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "user",
				},
			])
			: setAIConversation((prevArray) => [
				...prevArray,
				{
					content: Msg,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "user",
				},
			]);
		API.Therapist.Chatapi({
			data: {
				message_id: megID ? megID : currentChatId,
				phone: UserData.data.phone,
				name: UserData?.data?.name,
				message: Msg
			}
		}).then((response) => {
			if (response.data.status === 1) {
				let data = JSON.parse(response.data.data)
				setAiInputChat("");
				setAIConversation((prevArray) => [
					...prevArray,
					{
						content: data.data.message,
						created_at: new Date(),
						role: "assistant",
					},
				]);
				navigate(
					`../${AdminRoute?.Common?.PsychAi}?id=${data?.data.message_id}`,
					{ replace: true }
				)
			}
			setisGenerating(false);
		}).catch((err) => {
			console.log(err)
		})
		// }

	};



	const handleKeyPress = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			setRows(1);
			handleSubmitChat(AiInputChat);
			setAiInputChat("");
			e.preventDefault();
		} else if (e.key === "Enter" && e.shiftKey) {
			if (rows < 6) {
				setRows(rows + 1);
			}
			const cursorPosition = e.target.selectionStart;
			const newInputValue =
				AiInputChat.substring(0, cursorPosition) +
				"\n" +
				AiInputChat.substring(cursorPosition);
			setAiInputChat(newInputValue);
			e.preventDefault();
		}
	};

	return (
		<div className="d-flex h-dashboard">
			<div className="toggleIcon aitherapist">
				<div className="container-fluid">
					<div className="row">
						<div className="">
							<nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
								<h2 className="heading  mt-1 mb-1">Al Therapist</h2>
								<Header UserInfo={UserInfo} />
							</nav>
						</div>
						<div className="row">
							<div
								className="ai-div"
							// style={{
							// 	backgroundImage:
							// 		"url(" +
							// 		(useCase == 4
							// 			? AdamTheme
							// 			: "https://i.pinimg.com/564x/ec/5c/d9/ec5cd9f0428c94b39a271f0d73fa5e50.jpg") +
							// 		")",
							// }}
							>
								<div className="therapist-switches-div d-flex rounded-pill justify-content-end">
									{/* <Form className="float-left">
										<label className="toggle">
											<span className="toggle-label mr-2">Adam</span>
											<input
												className="toggle-checkbox"
												type="checkbox"
												checked={useCase == 4 ? false : true}
												onChange={toggleUseCase}
											/>
											<div className="toggle-switch" style={theme}></div>
											<span className="toggle-label">Eve</span>
										</label>
									</Form> */}
									<Dropdown>
										<Dropdown.Toggle variant="success" id="drNewopdown-basic">
											<i className="fa fa-ellipsis-v"></i>
										</Dropdown.Toggle>
										<Dropdown.Menu className="previouschat-dropdown chat-drop-menu">
											<Dropdown.Item onClick={() => [navigate(`../${AdminRoute?.Common?.PsychAi}`, { replace: true }), window.location.reload(true)]}>
												<h6 className="mt-0 font-weight-medium dropdown-header">
													New Chats{" "}
												</h6>
											</Dropdown.Item>
											<Dropdown.Item>
												<h6 className="mt-0 font-weight-medium dropdown-header">
													Previous Chats{" "}
												</h6>
											</Dropdown.Item>
											{perviousChatList?.map((item) => (
												<Dropdown.Item
													key={item?.message_id}
													onClick={() => [navigate(`../${AdminRoute?.Common?.PsychAi}?id=${item?._id}`, { replace: true }), getChatHistory(item?._id)]}>
													<div className="preview-item-content py-1 ">
														<p className="font-weight-medium small-text my-0">
															{item?.name}
														</p>
													</div>
												</Dropdown.Item >
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="col-md-12 mb-2 mt-5">
									<div className="scroller px-3">
										<div className="therapist-content-center position-relative mt-5">
											{AIConversation?.length ? (
												AIConversation?.map((val, index) => {
													return (
														<div className="mb-3 w-100" key={index}>
															{val?.role === "assistant" ? (
																<div className="d-flex justify-content-start">
																	<div className="chatbox-rec chat-reciving-light">
																		<p className="my-0 reciving-text">
																			{val?.content}
																		</p>
																	</div>
																</div>
															) : (
																<div className="d-flex justify-content-end">
																	<div className="chatbox-send chat-trasmitting-light">
																		<p className="my-0 trasmitting-text">
																			{val?.content}
																		</p>
																	</div>
																</div>
															)}
														</div>
													);
												})
											) : AIChat?.output?.content && (
												<div className="mb-3 w-100" >
													<div className="d-flex justify-content-start">
														<div className="chatbox-rec chat-reciving-light">
															<p className="my-0 reciving-text">
																{AIChat?.output?.content}
															</p>
														</div>
													</div>
												</div>
											)}
											{isGenerating ? <p className="generate_loader"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></p> : <></>}
										</div>
									</div>
									<div className="send-box">
										<textarea
											ref={textareaRef}
											placeholder="Write a message..."
											className="therapist-send-box-input rounded-pill"
											rows={rows}
											cols={50}
											autoComplete="off"
											value={isGenerating ? "" : AiInputChat}
											onChange={(e) => handleChange(e)}
											onKeyDown={handleKeyPress}
											disabled={isGenerating}
											style={{ resize: "none" }}
										/>
										<div className="send-box-icon">
											<Button
												className="btn btn--send"
												title="Send message"
												tabIndex="0"
												onClick={() => handleSubmitChat(AiInputChat)}
											>
												<svg
													className="btn__icon"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<line x1="22" y1="2" x2="11" y2="13"></line>
													<polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
												</svg>
											</Button>
										</div>
										{/* audio input code start */}
										<div>
											<AudioRecorder
												onRecordingComplete={(blob) => addAudioElement(blob)}
												onClick={() => onRecord(isRecording)}
												recorderControls={recorderControls}
												downloadOnSavePress={false}
												downloadFileExtension="mp3"
												showVisualizer={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</div >
	);
};

export default PsychAiCore;
