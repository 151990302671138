// import Page from "../Components/Page";
import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import Dashboard from "../Components/Dashboard/Dashboard";
import Profile from "../Components/Dashboard/Profile";
import Settings from "../Components/Dashboard/Settings";
import ProtectedRoute from "./ProtectedRoute";
import Profileedit from "../Components/Dashboard/Profileedit";

import PsychAi from "../Components/PsychAI/PsychAi"
import PlaygroundList from "../Components/Playground/PlaygroundList";
import CreateContent from "../Components/Playground/CreateContent";
import { Routes } from "react-router-dom";

//routes
const AdminRoute = [
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
 

  {
    path: RouteName.Common.Dashboard,
    element: (
       <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Dashboard />
       </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Profile,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Profile />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Settings,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Settings />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Common.Profileedit}`,
    element: <Profileedit />,
    exact: true,
  },

  {
    path: RouteName.Common.PsychAi,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <PsychAi />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.PlaygroundList,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <PlaygroundList />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.CreateContent,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <CreateContent />
      </ProtectedRoute>
    ),
    exact: true,
  },

];

export default AdminRoute;
