import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const Login = () => {
   
  const navigate = useNavigate();
  const initialValues = { InputType: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
 
 useEffect(() => {
    localStorage.clear();
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validate = (values) => {
    const errors = {};
    if (!values?.InputType) {
      errors.InputType = "Enter Email ID!";
    } if (emailRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Email ID !";
    }
    if (!values?.password) {
      errors.password = "Enter Password!";
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (emailRegex.test(formValues?.InputType)) {
        try {
          API.Auth.LoginAPIEmail({
            data: {
              email: formValues?.InputType,
              password: formValues?.password,
              "role": "Ryt-researschadmin",
            },
          }).then((response) => {
               console.log(response?.data?.status);

            if (response?.data?.status === 1) {
                navigate(`../${AdminRoute?.Common?.Dashboard}`);
               localStorage.setItem("AuthTokenResearschadmin", response?.data?.data?.token);
               localStorage.setItem("Researschadmin", JSON.stringify(response?.data));
              toast.success(response?.data?.message);
            } 
          });
        } catch (error) {
          console.log(error);
        }
        setIsSubmit(false)
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  //jsx
  return (
    <div className="container login-page">
      <div className="row main-container">
        <div className="col-md-6 col-sm-12 left-text">
          <div className="logo-div">
            <NavLink to="/">
              <img src="/images/logo.png" className="logo" alt="logo" />{" "}
            </NavLink>
          </div>
          <h1 className="heading">Take Charge of Your Mental Well-Being!</h1>
          <p className="mb-2 fw-bold">Are you ready to:</p>
          <p className="ps-3 mb-0">- Reduce stress and burnout</p>
          <p className="ps-3 mb-0">- Feel happier and more productive</p>
          <p className="ps-3">- Boost your mood and energy levels</p>
          <p className="subheading w-100">
            If yes, you’re in the right place!{" "}
          </p>
        </div>
        {/* col-md-6 */}
        <div className="col-md-6 col-sm-12">
          <div className="register">
            <form>
              <h2 className="heading">Login</h2>
              <div>
                <label>
                  <span className="text-danger fw-bold">*</span> Enter
                  registered E-Mail ID
                </label>
                <input
                   type="text"
                   name="InputType"
                   value={formValues?.InputType}
                   placeholder="Enter your E-Mail ID"
                   onChange={handleChange}
                />
                <p className="text-danger">{formErrors?.InputType}</p>
                <label className="mt-2">
                  <span className="text-danger fw-bold">*</span> Password
                </label>
                <input
                  type="text"
                  name="password"
                  value={formValues?.password}
                  placeholder="Enter your Password"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <p className="text-danger">{formErrors?.password}</p>
              {/* <p className="text-danger">{ApiError}</p> */}
              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3"
                disabled={isSubmit}
                onClick={(e) => handleSubmit(e)}
              >
                Login
              </button>

              {/* {loading ? <div className="loader_orange"></div> : <></>} */}

              <p className="text-end">Forgot Password</p>
            </form>
          </div>
        </div>
        {/* col-md-6 */}
      </div>
    </div>
  );
};
export default Login;
