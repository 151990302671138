import React, { useState } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import { useNavigate } from "react-router-dom";


const CreateContent = () => {
    const navigate = useNavigate();
    const options = ['Mental Health Resources', 'Videos', 'Self Assesment Tests'];
    const [selectedOption, setSelectedOption] = useState(0);
    const type = ['Blog', 'Whitepaper', 'Research Paper'];
    const [selectedType, setSelectedType] = useState(0);
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [date, setDate] = useState('');
    const [url, setURL] = useState('')
    const handleSubmitResources = (e) => {
        try {
            API.Info.CreateInfo({
              data: {
                name: 'health',
                header: title,
                created_by: author,
                type: type[selectedType],
                createdAt: date,
                url: url,
              },
            }).then((response) => {
                 console.log(response); 
                 navigate(`../${AdminRoute?.Common?.PlaygroundList}`);

            });
          } catch (error) {
            console.log(error);
          }
    }

    const handleSubmitVideo = (e) => {
        try {
            API.Info.CreateInfo({
              data: {
                name: 'kind',
                header: title,
                created_by: author,
                url: url,
                createdAt: date,
              },
            }).then((response) => {
                 console.log(response); 
                 navigate(`../${AdminRoute?.Common?.PlaygroundList}`);

            });
          } catch (error) {
            console.log(error);
          }
    }


    
    return (
        <div className="d-flex h-dashboard">
            <Sidebar />
            <div className='toggleIcon'>
                <div className="container-fluid">
                    <div className="row list create-content">
                        <div className="">
                            <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                                <div className='w-100 h-head'>
                                    <h2 className='heading pt-0 mt-1 mb-1'>
                                        <i
                                            className="fa fa-chevron-left cursor_pointer pe-2"
                                            aria-hidden="true"
                                            onClick={() => navigate(-1)}
                                        ></i>{" "}
                                        Create Content</h2>
                                </div>
                                <Header />
                            </nav>
                        </div>

                        <div className='mt-2 mb-3'>
                            <div className='col'>
                                <select
                                    value={selectedOption}
                                    onChange={(e) => { setSelectedOption(parseInt(e.target.value)) }}
                                    className="form-select bg-white"
                                    aria-label="Default select example"
                                >
                                    {
                                        options.map((item, index) => (
                                            <option key={index} value={index}> {item} </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            selectedOption === 0 ? (
                                <div className="content-wrap">
                                    <div className="col-md-12 b-0 pe-0 profile">
                                        <div className="card-body payment-card-body p-0 profile-wrap">
                                            <div className="row p-3">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Title" className="form-label">
                                                                            Title
                                                                        </label>
                                                                        <input
                                                                            value={title}
                                                                            onChange={(e) =>{setTitle(e.target.value)}}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Title"
                                                                            aria-describedby="Title"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Author" className="form-label">
                                                                            Author/Creator
                                                                        </label>
                                                                        <input
                                                                            value={author}
                                                                            onChange={(e)=>{setAuthor(e.target.value)}}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Author Name"
                                                                            aria-describedby="Author"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Type" className="form-label">
                                                                            Type
                                                                        </label>
                                                                        <select 
                                                                            value={selectedType}
                                                                            onChange={(e) => {setSelectedType(e.target.value)}}
                                                                            className="type-form"
                                                                        >
                                                                            {
                                                                                type.map((item, index) => (
                                                                                    <option key={index} value={index}> {item} </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                <div className="mb-3">
                                                                        <label htmlFor="URL" className="form-label">
                                                                            URL
                                                                        </label>
                                                                        <input 
                                                                            value={url}
                                                                            onChange={(e)=>{setURL(e.target.value)}}
                                                                            type="text" 
                                                                            className='form-control'
                                                                            placeholder='Enter URL'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Published Date" className="form-label">
                                                                            Published Date
                                                                        </label>
                                                                        <input
                                                                            value={date}
                                                                            onChange={(e) => {setDate(e.target.value)}}
                                                                            type="date"
                                                                            className="form-control"
                                                                            placeholder="Enter Published Date"
                                                                            aria-describedby="Published Date"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div className="col-12">
                                                            <Button
                                                                type="submit"
                                                                className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0 me-0"
                                                                onClick={(e)=>handleSubmitResources(e)}
                                                            >
                                                                Create{" "}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                selectedOption === 1 ? (
                                    <div className="content-wrap">
                                    <div className="col-md-12 b-0 pe-0 profile">
                                        <div className="card-body payment-card-body p-0 profile-wrap">
                                            <div className="row p-3">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Title" className="form-label">
                                                                            Title
                                                                        </label>
                                                                        <input
                                                                            value={title}
                                                                            onChange={(e) =>{setTitle(e.target.value)}}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Title"
                                                                            aria-describedby="Title"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Author" className="form-label">
                                                                            Author/Creator
                                                                        </label>
                                                                        <input
                                                                            value={author}
                                                                            onChange={(e)=>{setAuthor(e.target.value)}}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Author Name"
                                                                            aria-describedby="Author"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                <div className="mb-3">
                                                                        <label htmlFor="URL" className="form-label">
                                                                            URL
                                                                        </label>
                                                                        <input 
                                                                            value={url}
                                                                            onChange={(e)=>{setURL(e.target.value)}}
                                                                            type="text" 
                                                                            className='form-control'
                                                                            placeholder='Enter URL'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Published Date" className="form-label">
                                                                            Published Date
                                                                        </label>
                                                                        <input
                                                                            value={date}
                                                                            onChange={(e) => {setDate(e.target.value)}}
                                                                            type="date"
                                                                            className="form-control"
                                                                            placeholder="Enter Published Date"
                                                                            aria-describedby="Published Date"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div className="col-12">
                                                            <Button
                                                                type="submit"
                                                                className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0 me-0"
                                                                onClick={(e)=>handleSubmitVideo(e)}
                                                            >
                                                                Create{" "}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ) : (
                                    <div className="content-wrap">
                                    <div className="col-md-12 b-0 pe-0 profile">
                                        <div className="card-body payment-card-body p-0 profile-wrap">
                                            <div className="row p-3">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Title" className="form-label">
                                                                            Title
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Title"
                                                                            aria-describedby="Title"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Author" className="form-label">
                                                                            Author
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Author Name"
                                                                            aria-describedby="Author"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Creator" className="form-label">
                                                                            Creator
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Creator Name"
                                                                            aria-describedby="Creator"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Type" className="form-label">
                                                                            Type
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Type"
                                                                            aria-describedby="Type"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Published Date" className="form-label">
                                                                            Published Date
                                                                        </label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            placeholder="Enter Published Date"
                                                                            aria-describedby="Published Date"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div className="col-12">
                                                            <Button
                                                                type="submit"
                                                                className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0 me-0"
                                                            >
                                                                <NavLink to={AdminRoute?.Common?.CreateContent}>
                                                                    Create{" "}
                                                                </NavLink>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            )
                        }
                    </div>
                    <Footer />
                </div>
            </div>
        </div >
    );
}

export default CreateContent;
