// App.js
import React from "react";
import Sidebar from "../Common/Sidebar";

import PsychAiCore from "./PsychAiCore";

function Dashboard() {
	return (
		<div className="d-flex h-dashboard">
			<Sidebar />
			<PsychAiCore/>
		</div>
	);
}

export default Dashboard;
