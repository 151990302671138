import React, { useEffect, useState, createContext } from 'react';
import '../src/Assets/Css/Style.css';
import Lazy from '../src/Route/Lazy';
import API from './Api/Api';
// import { useLocation } from "react-router-dom";

const FeatrureList = createContext();

function App() {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(window.navigator.onLine);
  const [CurrentPlan, setCurrentPlan] = useState([]);
  const isAuthenticated = localStorage.getItem("WellnessCoachData");


  useEffect(() => {
    MyPlanlfeatures()
    function handleOnline() {
      setOnline(true);
    }

    function handleOffline() {
      setOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const MyPlanlfeatures = () => {
    if (isAuthenticated) {
      try {
        API.Payment.MyPlanlfeatures()
          .then((response) => {
            if (response.status === 200) {
              setCurrentPlan(response.data.data)
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="App">
      {isOnline ?
        <FeatrureList.Provider value={{ CurrentPlan, setCurrentPlan }}>
          <Lazy />
        </FeatrureList.Provider>
        :
        <div className="home-page">
          <div className="container-fluid main-container">
            <div className="row library-row center-me padding-top-40-percent">
              <div className="col-12 center-me">
                <div className="errorimagediv center-me">
                  {/*   <img src={nointernetImg} alt="nointernet" className="errorimage" />*/}
                </div>
                <h1 className="text-white text-center heading-error">
                  Internet Connection Error
                </h1>
                <p className="mb-4 text-white">
                  Please check your connection and try again.
                </p>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  );
}

export default App;
export { FeatrureList };
