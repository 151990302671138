import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { Pagination } from 'react-bootstrap';


const PlaygroundList = () => {
    const [list, setlist] = useState([]);
    const [LoadingClient, setLoadingClient] = useState(false);
    const [tab, setTab] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = list.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(list.length / itemsPerPage);
    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    useEffect(() => {
        getPlaygroundList("health");
    }, []);

    const getPlaygroundList = (Key) => {
        setLoadingClient(true)
        try {
            API.Common.Playgroundlist({
                key: Key,

            }).then((response) => {
                console.log(response.data);
                setlist(response?.data?.data_all);
                setLoadingClient(false)
            });
        } catch (error) {
            console.log(error);
            setLoadingClient(false)
        }
    };

    const handleSelect = (Key) => {
        console.log(Key);
        if (Key === "MentalHealth") {
            getPlaygroundList("health");
            // console.log("all",Key);
            setTab(Key);
        }
        if (Key === "Videos") {
            getPlaygroundList("kind");
            // console.log("active",Key);
            setTab(Key);
        }
        if (Key === "Assessment") {
            getPlaygroundList("selfAsses");
            // console.log("inactive",Key);
            setTab(Key);
        }
    };


    const download = (e,val) => {
        e.preventDefault();
        // alert("Download");
        const response = {
            file_path:val
        };
        window.open(response.file_path);
    };

    
    return (
        <div className="d-flex h-dashboard">
            <Sidebar />
            <div className='toggleIcon'>
                <div className="container-fluid">
                    <div className="row list">
                        <div className="">
                            <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                                <div className='w-100 h-head'>
                                    <h2 className='heading pt-0 mt-1 mb-1'>Playground</h2>
                                </div>
                                <Header />
                            </nav>
                        </div>

                        <div className='row mt-2'>
                            <div className='col'>
                                <h6 className='fw-normal'>Total Published Content: <span className='fw-bold'>08</span></h6>
                            </div>
                        </div>

                        <div className='w-50 mt-2 sort-filter position-absolute'>
                            <div className='col px-0 employee-dropdown d-flex justify-content-end align-items-center'>
                                <Dropdown className='float-end'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <div className='icon-div me-0'><i className="fa fa-filter"></i></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        < NavLink to={AdminRoute.Common.Profile}>Employee</NavLink><br></br>
                                        < NavLink to={AdminRoute.Common.Payment}>Leader</NavLink><br></br>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button type="submit" className="create-new_notes notes-btn btn btn-primary mb-0 mt-0 ms-2 me-0 button_common approve-btn">< NavLink to={AdminRoute.Common.CreateContent}>Create Content</NavLink></button>

                            </div>
                        </div>

                        <div className="col-md-12 mb-2 list-item playground-details-v-height">
                            <div className="sessions-tabs mt-2">
                                <Tabs
                                    defaultActiveKey="MentalHealth"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 px-0"
                                    onSelect={handleSelect}
                                >

                                    <Tab eventKey="MentalHealth" title="Mental Health Resources">
                                        <div className='organizations-list mt-4 playground-v-height'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No.</th>
                                                        <th scope="col">Content</th>
                                                        <th scope="col">Created By</th>
                                                        <th scope="col">Published Date</th>
                                                        <th scope="col">Content Type</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {LoadingClient ? (
                                                        <figure>
                                                            <div className="dot white"></div>
                                                            <div className="dot"></div>
                                                            {/* <div className="dot"></div> */}
                                                            {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                                                        </figure>
                                                    ) : currentItems.length === 0 ? (
                                                        <div
                                                            className="alert alert-warning digital-notes-alert"
                                                            role="alert"
                                                        >
                                                            Data Not Found
                                                        </div>
                                                    ) : (
                                                        currentItems &&
                                                        currentItems.map((val, index) => (
                                                            <tr>
                                                                <td>####</td>
                                                                <td>{val?.header}</td>
                                                                <td>{val?.created_by}</td>
                                                                <td>{moment(val?.createdAt).format("LL")}</td>
                                                                <td>{val?.type}</td>
                                                                <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Published</div></td>
                                                                <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'  onClick={(e)=>download(e,val?.url)}>View</button></td>
                                                            </tr>
                                                        )))}

                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                <Pagination>
                                                    <Pagination.First onClick={() => { setCurrentPage(1) }} />
                                                    <Pagination.Prev onClick={() => { setCurrentPage(currentPage - 1) }} />
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next onClick={() => { setCurrentPage(currentPage + 1) }} />
                                                    <Pagination.Last onClick={() => { setCurrentPage(totalPages) }} />
                                                </Pagination>
                                                <p className='mx-2'>Showing {currentPage} of {totalPages} </p>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Videos" title="Videos">
                                        <div className='organizations-list mt-4 playground-v-height'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No.</th>
                                                        <th scope="col">Content</th>
                                                        <th scope="col">Created By</th>
                                                        <th scope="col">Published Date</th>
                                                        <th scope="col">Content Type</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {LoadingClient ? (
                                                        <figure>
                                                            <div className="dot white"></div>
                                                            <div className="dot"></div>
                                                            {/* <div className="dot"></div> */}
                                                            {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                                                        </figure>
                                                    ) : currentItems.length === 0 ? (
                                                        <div
                                                            className="alert alert-warning digital-notes-alert"
                                                            role="alert"
                                                        >
                                                            Data Not Found
                                                        </div>
                                                    ) : (
                                                        currentItems &&
                                                        currentItems.map((val, index) => (
                                                            <tr>
                                                                <td>####</td>
                                                                <td>{val?.header}</td>
                                                                <td>{val?.created_by}</td>
                                                                <td>{moment(val?.createdAt).format("LL")}</td>
                                                                <td>{val?.type}</td>
                                                                <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Published</div></td>
                                                                <td>
                                                                    <button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark' 
                                                                 onClick={(e)=>download(e,val?.url)}
                                                                >View</button></td>
                                                            </tr>
                                                        )))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                <Pagination>
                                                    <Pagination.First onClick={() => { setCurrentPage(1) }} />
                                                    <Pagination.Prev onClick={() => { setCurrentPage(currentPage - 1) }} />
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next onClick={() => { setCurrentPage(currentPage + 1) }} />
                                                    <Pagination.Last onClick={() => { setCurrentPage(totalPages) }} />
                                                </Pagination>
                                                <p className='mx-2'>Showing {currentPage} of {totalPages} </p>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Assessment" title="Self-Assessment Tests">
                                        <div className='organizations-list mt-4 playground-v-height'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No.</th>
                                                        <th scope="col">Content</th>
                                                        <th scope="col">Created By</th>
                                                        <th scope="col">Published Date</th>
                                                        <th scope="col">Content Type</th>
                                                        <th scope="col">Status</th>
                                                        {/* <th scope="col">Details</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {LoadingClient ? (
                                                        <figure>
                                                            <div className="dot white"></div>
                                                            <div className="dot"></div>
                                                            {/* <div className="dot"></div> */}
                                                            {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                                                        </figure>
                                                    ) : currentItems.length === 0 ? (
                                                        <div
                                                            className="alert alert-warning digital-notes-alert"
                                                            role="alert"
                                                        >
                                                            Data Not Found
                                                        </div>
                                                    ) : (
                                                        currentItems &&
                                                        currentItems.map((val, index) => (
                                                            <tr>
                                                                <td>####</td>
                                                                <td>{val?.header}</td>
                                                                <td>{val?.created_by}</td>
                                                                <td>{moment(val?.createdAt).format("LL")}</td>
                                                                <td>{val?.type}</td>
                                                                <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Published</div></td>
                                                                {/* <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'  onClick={(e)=>download(e,val?.url)}>View</button></td> */}
                                                            </tr>
                                                        )))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                <Pagination>
                                                    <Pagination.First onClick={() => { setCurrentPage(1) }} />
                                                    <Pagination.Prev onClick={() => { setCurrentPage(currentPage - 1) }} />
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next onClick={() => { setCurrentPage(currentPage + 1) }} />
                                                    <Pagination.Last onClick={() => { setCurrentPage(totalPages) }} />
                                                </Pagination>
                                                <p className='mx-2'>Showing {currentPage} of {totalPages} </p>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div >






    );
}

export default PlaygroundList;
