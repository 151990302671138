import BaseApi from "./BaseApi";

const API = {
  //Researschadmin auth apis
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/login`,
        data: props.data,
        tokenRequired: false,
      });
    },
  },
  Common: {
    GetTherapistDetailsAPI: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    DashboardCountapi: (props) => {
      return BaseApi({
        method: "GET",
        url: `superadmin/totalCount`,
        tokenRequired: true,
      });
    },
    Playgroundlist: (props) => {
      return BaseApi({
        method: "GET",
        url: `info/infolist?key=${props.key}`,
        tokenRequired: true,
      });
    },
  },
  Therapist: {
    TherpistAdd: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/theropistAdd`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getTherpistDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropistdtls?date=${props.date}`,
        tokenRequired: true,
      });
    },
    CreatMessageid: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/creatmessageid`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Chatapi: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/chatapi`,
        data: props.data,
        tokenRequired: true,
      });
    },
    ChatHistory: (props) => {
      return BaseApi({
        method: "GET",
        url: props.msgId
          ? `/aiconnect/aichatlist?messageid=${props.msgId}`
          : `/aiconnect/aichatlist`,
        tokenRequired: true,
      });
    },
    Aichatlisthistory: () => {
      return BaseApi({
        method: "GET",
        url: `/aiconnect/aichatlisthistory`,
        tokenRequired: true,
      });
    },
  },

  NotificationApis: {
    getAllnotificationApi: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/notification`,
        tokenRequired: true,
      });
    },
  },
  Info: {
    CreateInfo: (props) => {
      return BaseApi({
        method: "POST",
        url: `/info/createinfo`,
        data: props.data,
        tokenRequired: true,
      });
    }
  }
};





export default API;
