// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import API from "../../Api/Api";
import Dropdown from "react-bootstrap/Dropdown";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

function Profile() {
  const navigate = useNavigate();
  const [UserInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(response?.data?.data);
          setUserInfo(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  //jsx
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <h2 className="heading  mt-1 mb-1">My Profile</h2>
                <Header />
              </nav>
            </div>

            <span className="my-checklist-modal">
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <button onClick={closeModal} className="close-btn ">
                  <img src={plusIcon} />{" "}
                </button>
                <div>
                  <input
                    type="text"
                    placeholder="Max Clients"
                    className="my-task-add"
                    disabled
                  />
                  <p className="mt-3">
                    <span class="text-danger fw-bold">*</span> Edit No. Of Max
                    Clients
                  </p>
                  <input
                    type="text"
                    placeholder="Enter No."
                    className="mb-3 "
                    name="Title"
                    // onChange={(e) => handleChange(e)}
                  />
                  {/* <p className="text-danger">{formErrors?.Title}</p> */}
                </div>
                <button
                  type="submit"
                  className="button_common w-100 mt-2 mb-0 text-center"
                  role="button"
                  // onClick={submit}
                >
                  Set
                </button>
              </Modal>
              {/* **************** View Modal Starts ************************** */}
            </span>

            <div className="profile-wrap-box">
              <div className="col-md-12 b-0 pe-0">
                <div className="card-body payment-card-body p-0 profile-wrap">
                  <div className="row">
                    <div className="col-12">
                      <div className="profile-header-box">
                        <div className="col px-0 employee-dropdown setting-dropdown">
                          <Dropdown className="float-end mt-2">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <div className="icon-div me-0 bg-transparent">
                                <i className="fa fa-cog"></i>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <NavLink to={AdminRoute.Common.Profile}>
                                My Calender
                              </NavLink>
                              <br></br>
                              <NavLink onClick={openModal}>
                                Edit Max. No of Employees
                              </NavLink>
                              <br></br>
                              <NavLink to={AdminRoute.Common.MyReferrals}>
                                My Referrals
                              </NavLink>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="profile-image-box ms-4 d-flex justify-content-center align-items-center">
                        <img src={DefaultUserImg}></img>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3 p-3">
                    <div className="col-12">
                      <h6 className="mb-2">Personal Details</h6>
                      <hr />
                    </div>

                    <form>
                      <div className="row">
                        <div className="col-7">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label htmlFor="Full Name" className="form-label">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control"
                                  placeholder="Full name"
                                  aria-describedby="Full Name"
                                  defaultValue={UserInfo?.fullname}
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Email" className="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  readOnly
                                  placeholder="Email"
                                  aria-describedby="Email"
                                  defaultValue={UserInfo?.email}
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Contact No" className="form-label">
                                  Contact No.
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  readOnly
                                  placeholder="Contact No."
                                  aria-describedby="Contact No"
                                  defaultValue={UserInfo?.phone}
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Age" className="form-label">
                                  Age
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  readOnly
                                  placeholder="Age"
                                  aria-describedby="Age"
                                  defaultValue={UserInfo?.age}
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Gender" className="form-label">
                                  Gender
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  placeholder="Gender"
                                  aria-describedby="Gender"
                                  defaultValue={UserInfo?.gender}
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="Years of experience"
                                  className="form-label"
                                >
                                  Years of experience
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  readOnly
                                  placeholder="Years of experience"
                                  aria-describedby="Years of experience"
                                  defaultValue={UserInfo?.experience}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label htmlFor="Expertise" className="form-label">
                                  Expertise:
                                </label>
                                <textarea
                                  class="form-control"
                                  readOnly
                                  placeholder="Leave a comment here"
                                  rows="9"
                                  id="floatingTextarea"
                                  defaultValue={UserInfo?.experties}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <Button
                            type="submit"
                            className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0"
                          >
                            <NavLink to={AdminRoute?.Common?.Profileedit}>
                              Edit Profile{" "}
                            </NavLink>
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Profile;
