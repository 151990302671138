// Sidebar.js
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { FeatrureList } from "../../App";
import { checkDate } from "./Functions";

function Sidebar() {
  const navigate = useNavigate()
  const featureListContext = useContext(FeatrureList);

  const Logoutfun = () => {
    localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenWellnessCoach");
    localStorage.removeItem("WellnessCoachData");
    navigate(`../${AdminRoute?.Auth?.Login}`)
  }

  return (
    <div id="sidebar-wrapper" className="sidebar">
      <div className="sidebar-heading text-center header-navbar">
        <NavLink to="/">
          <img src="/images/logo.png" className="logo" alt="logo" />{" "}
        </NavLink>
      </div>
      <div className="list-group">
        <aside className="dropdown-menu-list" id="menu">
          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="/"
              to={AdminRoute?.Common?.Dashboard}
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-door d-icon" viewBox="0 0 16 16">
                  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                </svg>
              </span>
              Dashboard
            </NavLink>
          </li>

          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="/"
              to={AdminRoute?.Common?.PsychAi}
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cpu d-icon" viewBox="0 0 16 16">
                  <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0m-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                </svg>
              </span>
              Psych AI
            </NavLink>
          </li>

          <li className="dropdown">
            <NavLink
              className="pt-2"
              eventkey="/"
              to={AdminRoute?.Common?.PlaygroundList}
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-backpack4 d-icon" viewBox="0 0 16 16">
                  <path d="M4 9.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 0 1-1 0V10z" />
                  <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1c0 .52.198.993.523 1.349A.5.5 0 0 0 2 6.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.5a.5.5 0 0 0-.023-.151c.325-.356.523-.83.523-1.349V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m0 1a1 1 0 0 0-1 1h2a1 1 0 0 0-1-1M3 14V6.937q.24.062.5.063h4v.5a.5.5 0 0 0 1 0V7h4q.26 0 .5-.063V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1m9.5-11a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
                </svg>
              </span>
              Playground
            </NavLink>
          </li>

          <li className="dropdown">
            <button
              className="pt-2"
              onClick={() => Logoutfun()}
            >
              <span>
                <img src="/images/logout.png" className="d-icon" alt="logo" />
              </span>
              Logout
            </button>
          </li>
        </aside>
      </div>
    </div>
  );
}

export default Sidebar;
