const RouteName = {
  Auth: {
    Login: "/login",
  },
  Common: {
    Dashboard: "/",
    Profile: "/Profile",
    Settings: "/Settings",
    PageNotFound: "*",
    Profileedit: "/Profileedit",
    
    PsychAi: "/PsychAi",
    PlaygroundList: "/PlaygroundList",
    CreateContent: "/CreateContent",
  },
};

export default RouteName;
